import date from 'date-and-time';
import fetch from 'isomorphic-unfetch';
import qs from 'qs';
import markdownit from 'markdown-it';
import { wait } from 'next/dist/lib/wait';
import { AvailableLanguages, blogAvailableLanguages } from '../../redux/appTypes';
import { config } from '../../config/config';

const md = markdownit({
  html: true,
  breaks: true
});

export interface BlogPostsForSiteMapResponse {
  blogPosts: {
    id: number;
    attributes: {
      slug: string;
      locale: string;
      localizations: {
        data: {
          attributes: {
            slug: string;
            locale: string;
          };
        }[];
      };
    };
  }[];
}

export interface AssetItemFormat {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
}

export interface Asset {
  id: number;
  attributes: {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: {
      large?: AssetItemFormat;
      small?: AssetItemFormat;
      medium?: AssetItemFormat;
      thumbnail?: AssetItemFormat;
    };
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: null;
    createdAt: string;
    updatedAt: string;
  };
}

export interface BlogPostTag {
  id: number;
  attributes: {
    name: string;
    createdAt: string;
    updatedAt: string;
    locale: string;
  };
}

export interface BlogPostListItem {
  id: number;
  attributes: {
    title: string;
    intro: string;
    introHtml: string;
    blogPostTags: {
      data: BlogPostTag[];
    };
    slug: string;
    publishedAt: string;
    updatedAt: string;
    originallyPublishedAt: string;
    promoImage: {
      data: Asset | null;
    };
  };
}

export interface RelatedBlogPost {
  id: number;
  attributes: Pick<
    BlogPostAttributes,
    'title' | 'intro' | 'introHtml' | 'slug' | 'originallyPublishedAt' | 'publishedAt' | 'promoImage'
  >;
}

export interface BlogPostAttributes {
  title: string;
  metaDescription?: string;
  intro: string;
  introHtml: string;
  content: string;
  contentHtml: string;
  blogPostTags: {
    data: BlogPostTag[];
  };
  localizations: {
    data: {
      attributes: {
        slug: string;
        locale: string;
      };
    }[];
  };
  slug: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  originallyPublishedAt: string;
  promoImage: {
    data: Asset;
  };
  socialImage: {
    data: Asset | null;
  };
  relatedBlogPosts: {
    data: RelatedBlogPost[];
  };
}

export interface BlogPost {
  id: number;
  attributes: BlogPostAttributes;
}

export interface BlogPostsResponse {
  data: BlogPostListItem[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

const cachedBlogPosts: Record<string, BlogPostsResponse> = {};
export const fetchBlogPosts: (
  language: AvailableLanguages,
  limit: number,
  page: number,
  filterByTags: string[] | undefined,
  includeDrafts: boolean,
  useCache?: boolean,
  retryCount?: number
) => Promise<BlogPostsResponse> = async (
  language,
  limit,
  page,
  filterByTags,
  includeDrafts,
  useCache = false,
  retryCount = 5
) => {
  const blogLanguage = blogAvailableLanguages.includes(language) ? language : 'en';
  const cacheKey = `${blogLanguage}-${limit}-${page}-${(filterByTags ?? []).sort().join(';')}-${includeDrafts}`;
  const cachedResponse = cachedBlogPosts[cacheKey];
  if (useCache && cachedResponse) {
    return cachedResponse;
  }
  const params = {
    pagination: {
      page: page + 1,
      pageSize: limit
    },
    sort: ['updatedAt:desc'],
    populate: ['blogPostTags', 'promoImage'],
    fields: ['title', 'intro', 'slug', 'originallyPublishedAt', 'publishedAt', 'updatedAt'],
    publicationState: includeDrafts ? 'preview' : 'live',
    locale: blogLanguage,
    filters: {
      blogPostTags: filterByTags
        ? {
            $or: filterByTags.map((tag) => ({
              name: {
                $eq: tag
              }
            }))
          }
        : undefined
    }
  };
  const url = `${config.strapi.url}/api/blog-posts?${qs.stringify(params, {
    encodeValuesOnly: true // prettify URL
  })}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.strapi.token}`
    }
  });

  if (!response.ok) {
    if (retryCount !== 0) {
      await wait(1000);
      return fetchBlogPosts(language, limit, page, filterByTags, includeDrafts, useCache, retryCount - 1);
    }
    throw new Error(`No blog post found for: ${[blogLanguage, limit, page, filterByTags, includeDrafts]}`);
  }
  const data = (await response.json()) as BlogPostsResponse;
  data.data.forEach((blogPost) => {
    // eslint-disable-next-line no-param-reassign
    blogPost.attributes.introHtml = md.render(blogPost.attributes.intro);
  });
  if (useCache) {
    cachedBlogPosts[cacheKey] = data;
  }
  return data;
};

export interface BlogPostResponse {
  blogPost: BlogPost | null;
}

export const fetchBlogPost: (
  language: AvailableLanguages,
  slug: string,
  includeDrafts: boolean,
  retryCount?: number
) => Promise<BlogPostResponse> = async (language, slug, includeDrafts = false, retryCount = 5) => {
  const params = {
    pagination: {
      page: 1,
      pageSize: 1
    },
    populate: {
      blogPostTags: true,
      promoImage: true,
      socialImage: true,
      localizations: {
        fields: ['slug', 'locale']
      },
      relatedBlogPosts: {
        fields: ['title', 'intro', 'slug', 'originallyPublishedAt', 'publishedAt', 'updatedAt'],
        populate: ['promoImage']
      }
    },
    fields: [
      'title',
      'content',
      'slug',
      'originallyPublishedAt',
      'publishedAt',
      'updatedAt',
      'intro',
      'locale',
      'metaDescription'
    ],
    publicationState: includeDrafts ? 'preview' : 'live',
    locale: 'all',
    filters: {
      slug: {
        $eq: slug
      }
    }
  };
  const url = `${config.strapi.url}/api/blog-posts?${qs.stringify(
    { ...params },
    {
      encodeValuesOnly: true // prettify URL
    }
  )}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.strapi.token}`
    }
  });
  if (!response.ok) {
    if (retryCount !== 0) {
      await wait(1000);
      return fetchBlogPost(language, slug, includeDrafts, retryCount - 1);
    }
    throw new Error(`No blog post found for: ${slug}`);
  }
  const json = await response.json();
  const blogPost: BlogPost | null = json.data?.[0] ?? null;
  if (blogPost) {
    blogPost.attributes.introHtml = md.render(blogPost.attributes.intro);
    blogPost.attributes.contentHtml = md.render(blogPost.attributes.content);
  }
  return {
    blogPost
  };
};

export interface NeighbourBlogPost {
  id: number;
  attributes: {
    title: string;
    slug: string;
  };
}

export interface NeighbourBlogPostResponse {
  blogPost: NeighbourBlogPost | null;
}

const cachedNeighboursPosts: Record<string, NeighbourBlogPostResponse> = {};
export const fetchNeighbourBlogPost: (
  language: AvailableLanguages,
  postId: number,
  side: 'left' | 'right',
  includeDrafts: boolean,
  useCache?: boolean,
  retried?: boolean
) => Promise<NeighbourBlogPostResponse> = async (
  language,
  postId,
  side,
  includeDrafts,
  useCache = false,
  retried = false
) => {
  const cacheKey = `${language}-${postId}-${side}-${includeDrafts}`;
  const cachedResponse = cachedNeighboursPosts[cacheKey];
  if (useCache && cachedResponse) {
    return cachedResponse;
  }
  const comparisonParam = side === 'left' ? '$gt' : '$lt';
  const params = {
    pagination: {
      page: 1,
      pageSize: 1
    },
    sort: ['updatedAt:desc'],
    fields: ['title', 'slug'],
    publicationState: includeDrafts ? 'preview' : 'live',
    locale: language,
    filters: {
      id: {
        [comparisonParam]: postId
      }
    }
  };
  const url = `${config.strapi.url}/api/blog-posts?${qs.stringify(params, {
    encodeValuesOnly: true // prettify URL
  })}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.strapi.token}`
    }
  });
  if (!response.ok) {
    if (!retried) {
      await wait(1000);
      return fetchNeighbourBlogPost(language, postId, side, includeDrafts, useCache, true);
    }
    throw new Error(`No neighboaring blog post found for: ${[language, postId, side, includeDrafts]}`);
  }
  const json = await response.json();
  const blogPost: BlogPost | null = json.data?.[0] ?? null;
  const returnValue = {
    blogPost
  };
  if (useCache) {
    cachedNeighboursPosts[cacheKey] = returnValue;
  }
  return returnValue;
};

export const fetchBlogPostsForSiteMapSinglePage = async (includeDrafts: boolean, page: number) => {
  const params = {
    pagination: {
      page,
      pageSize: 1
    },
    locale: 'en',
    fields: ['slug', 'locale'],
    populate: {
      localizations: {
        fields: ['slug', 'locale']
      }
    },
    publicationState: includeDrafts ? 'preview' : 'live'
  };
  const url = `${config.strapi.url}/api/blog-posts?${qs.stringify(params, {
    encodeValuesOnly: true // prettify URL
  })}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.strapi.token}`
    }
  });
  const json = await response.json();
  const blogPosts = json.data;
  const { pageCount } = json.meta.pagination;
  return {
    blogPosts,
    pageCount
  };
};

export const fetchBlogPostsForSiteMap: (includeDrafts: boolean) => Promise<BlogPostsForSiteMapResponse> = async (
  includeDrafts
) => {
  let page = 1;
  let pageCount: number | null = null;
  let blogPosts: BlogPostsForSiteMapResponse['blogPosts'] = [];
  while (pageCount === null || pageCount >= page) {
    // eslint-disable-next-line no-await-in-loop
    const response = await fetchBlogPostsForSiteMapSinglePage(includeDrafts, page);
    blogPosts = [...blogPosts, ...response.blogPosts];
    page += 1;
    pageCount = response.pageCount;
  }

  return {
    blogPosts
  };
};

export const formattedBlogPublishedDate = (blogPost: BlogPost | BlogPostListItem): string => {
  const publishDate = new Date(blogPost.attributes.updatedAt ?? blogPost.attributes.publishedAt ?? '');
  return date.format(publishDate, 'MMMM D, YYYY');
};

export const getLargestImage = (
  asset: Asset | undefined | null,
  maxSize: 'large' | 'medium' | 'small' | 'thumbnail' | 'original'
) => {
  if (!asset) {
    return undefined;
  }
  if (maxSize === 'original' && asset.attributes.url) {
    return asset.attributes.url;
  }
  if (maxSize === 'large' && asset.attributes.formats?.large?.url) {
    return asset.attributes.formats.large.url;
  }
  if (maxSize === 'medium' && asset.attributes.formats?.medium?.url) {
    return asset.attributes.formats.medium.url;
  }
  if (maxSize === 'small' && asset.attributes.formats?.small?.url) {
    return asset.attributes.formats.small.url;
  }
  if (maxSize === 'thumbnail' && asset.attributes.formats?.thumbnail?.url) {
    return asset.attributes.formats.thumbnail.url;
  }
  return asset.attributes.url;
};

const imageCMSRegex = /https:\/\/cms-fym\.s3\.nl-ams.scw\.cloud\/(.+)/gm;
// https://cms-fym.s3.nl-ams.scw.cloud/large_download_audio_from_youtube_08ac407772.png
export const getIMGIXOptimisedImage = (
  asset: Asset | undefined | null,
  maxSize: 'large' | 'medium' | 'small' | 'thumbnail' | 'original'
) => {
  let imgixMaxSize = 1200;
  switch (maxSize) {
    default:
    case 'original':
      imgixMaxSize = 1600;
      break;
    case 'large':
      imgixMaxSize = 1200;
      break;
    case 'medium':
      imgixMaxSize = 1000;
      break;
    case 'small':
      imgixMaxSize = 800;
      break;
    case 'thumbnail':
      imgixMaxSize = 600;
      break;
  }
  const url = getLargestImage(asset, 'original');
  if (!url) {
    return undefined;
  }
  return url.replace(
    imageCMSRegex,
    `https://cms-fym.imgix.net/$1?auto=compress,format&fit=fillmax&ch=Save-Data&w=${imgixMaxSize}&max-h=${imgixMaxSize}`
  );
};
